<template>
<div class="card-box-full">
  <div class="card-box-header">
    Domain Profile
  </div>

  <div class="form-group row" style="margin-top: 15px; padding: 5px">
    <div class="col-md-12">
      <div class="input-group">

        <input class="form-control" v-model="domain" autocomplete="on" placeholder="Enter a domain" :disabled="isSearching" @keyup.enter="search()">
        <div class="input-group-append" color="primary">
          <CButton @click="search()" color="primary" :disabled="isSearching || domain.length == 0" style="width: 80px">
            <div v-if="isSearching" class="spinner-border spinner-border-sm text-light" role="status" style="">
              <span class="sr-only"></span>
            </div>
            <div v-else>
              Lookup
            </div>
          </CButton>
        </div>
      </div>

      <!-- ----------------------------------------------------------------- -->


      <!-- ----------------------------------------------------------------- -->
      <div v-if="errorMessage">
        <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
      </div>

      <!-- ----------------------------------------------------------------- -->
      <div v-else-if="profile" style="margin: 15px 0px -20px 0px">

        <div class="card-box" style="width: 100%; margin-top: 10px; min-height: 70px; background-color: #f7f7f7">

          <div class="card-box" style="min-height: 50px; width: 100%; margin-top: 5px">
            <table width="100%">
              <tr style="font-size: 13px; font-weight: 600">
                <td align="center">Messages</td>
                <td align="center">Blocked</td>
                <td align="center">Allowed</td>
                <td align="center">First Seen</td>
                <td align="center">Last Seen</td>
                <td align="center"></td>
              </tr>
              <tr>
                <td align="center" width="100">
                  <span class="badge badge-pill badge-light">
                    {{ profile.total_messages.toLocaleString() }}
                  </span>
                </td>
                <td align="center" width="100">
                  <span v-if="profile.total_blocked == 0" class="badge badge-pill badge-light">
                    {{ profile.total_blocked.toLocaleString() }}
                  </span>
                  <span v-else class="badge badge-pill badge-danger">
                    {{ profile.total_blocked.toLocaleString() }}
                  </span>
                </td>
                <td align="center" width="100">
                  <span v-if="profile.total_allowed == 0" class="badge badge-pill badge-light">
                    {{ profile.total_allowed.toLocaleString() }}
                  </span>
                  <span v-else class="badge badge-pill badge-success">
                    {{ profile.total_allowed.toLocaleString() }}
                  </span>
                </td>
                <td align="center" width="120">
                  <span class="badge badge-pill badge-light">
                    <timeago :datetime="profile.first_seen"></timeago>
                  </span>
                </td>
                <td align="center" width="140">
                  <span class="badge badge-pill badge-light">
                    <timeago :datetime="profile.last_seen"></timeago>
                  </span>
                </td>
                <td align="center">

                </td>
              </tr>
            </table>
          </div>

          <CTabs variant="tabs" style="margin-top: 5px">
            <CTab title="Last Message">
              <div class="tab-box" style="padding: 20px 10px 0px 0px; min-height: 80px">
                <table width="100%">
                  <tr>
                    <td width="90" valign="top" align="center">
                      <div>
                        <span class="badge badge-pill badge-light">{{ reasonToString(profile.last_reason) }}</span>
                        <div v-if="profile.last_fingerprint" style="margin-top: 7px; cursor: pointer" @click="clickedFingerprint(profile.last_fingerprint)">
                          <CIcon name="cil-fingerprint" style="width: 20px; height: 20px" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="profile.last_message_blocked" class="alert alert-danger" style="padding: 5px">
                        <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.last_message }}
                      </div>
                      <div v-else class="alert alert-success" style="padding: 5px">
                        <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.last_message }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </CTab>
          </CTabs>


          <div class="card-box" style="min-height: 50px; width: 100%; margin-top: 15px; margin-bottom: 5px">

            <table width="100%">
              <tr>
                <td>
                  <div style="font-size: 14px; font-weight: 600">Recent fingerprints with this domain</div>
                </td>
                <td align="right" style="padding-right: 15px; font-size: 13px">
                  <span v-if="sort != 'last_seen'" class="interval-link" @click="clickedSort('last_seen')">Last Seen</span>
                  <span v-if="sort == 'last_seen'" style="font-weight: bold">Last Seen</span>
                  |
                  <span v-if="sort != 'times_seen'" class="interval-link" @click="clickedSort('times_seen')">Times Seen</span>
                  <span v-if="sort == 'times_seen'" style="font-weight: bold">Times Seen</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr style="margin-top: 5px">
                  <div v-if="isLoadingFingerprints" style="text-align: center">
                    <span class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 5px">
                      <span class="sr-only"></span>
                    </span>
                    <span style="padding-left: 7px"> Loading recent fingerprints...</span>
                  </div>
                  <div v-else-if="isLoadingFingerprintProfiles" style="text-align: center">
                    <span class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 5px">
                      <span class="sr-only"></span>
                    </span>
                    <span style="padding-left: 7px"> Loading fingerprint profiles...</span>
                  </div>
                  <div v-else-if="errorFingerprints">
                    <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorFingerprints }}</div>
                  </div>
                  <div v-else-if="errorFingerprintProfiles">
                    <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorFingerprintProfiles }}</div>
                  </div>
                  <div v-else>
                    <!-- ---------------------------------------- -->
                    <table width="100%">
                      <tr v-for="fingerprint in fingerprints">
                        <td>
                          <div>
                            <div v-if="fingerprintProfiles[fingerprint.fingerprint].last_message_blocked" class="alert alert-danger" style="padding: 5px">
                              <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(fingerprintProfiles[fingerprint.fingerprint].last_sender)">
                                {{ fingerprintProfiles[fingerprint.fingerprint].last_sender }}
                              </span>
                              <span style="font-size: 12px; ">

                                <span v-if="sort == 'last_seen'" style="margin-left: 3px">
                                  last seen <timeago :datetime="fingerprintProfiles[fingerprint.fingerprint].last_seen"></timeago>
                                </span>
                                <span v-else style="margin-left: 5px">seen <b>{{ fingerprint.times_seen.toLocaleString() }}</b> times</span>
                              </span>
                              <br>
                              <div style="cursor: pointer" @click="clickedFingerprint(fingerprint.fingerprint)">
                                <div v-if="fingerprintProfiles[fingerprint.fingerprint].obscured">
                                  {{ truncateMessage(fingerprintProfiles[fingerprint.fingerprint].obscured) }}
                                </div>
                                <div v-else>
                                  {{ truncateMessage(fingerprintProfiles[fingerprint.fingerprint].last_message) }}
                                </div>
                              </div>
                            </div>
                            <div v-else class="alert alert-success" style="padding: 5px">
                              <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(fingerprintProfiles[fingerprint.fingerprint].last_sender)">
                                {{ fingerprintProfiles[fingerprint.fingerprint].last_sender }}
                              </span>
                              <span style="font-size: 12px; ">
                                <span v-if="sort == 'last_seen'" style="margin-left: 3px">
                                  last seen <timeago :datetime="fingerprintProfiles[fingerprint.fingerprint].last_seen"></timeago>
                                </span>
                                <span v-else style="margin-left: 5px">seen <b>{{ fingerprint.times_seen.toLocaleString() }}</b> times</span>
                              </span>
                              <br>
                              <div style="cursor: pointer" @click="clickedFingerprint(fingerprint.fingerprint)">
                                <div v-if="fingerprintProfiles[fingerprint.fingerprint].obscured">
                                  {{ truncateMessage(fingerprintProfiles[fingerprint.fingerprint].obscured) }}
                                </div>
                                <div v-else>
                                  {{ truncateMessage(fingerprintProfiles[fingerprint.fingerprint].last_message) }}
                                </div>
                              </div>
                            </div>

                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Domain-Lookup',
  data() {
    return {
      errorFingerprints: null,
      errorFingerprintProfiles: null,
      errorMessage: null,
      domain: "",
      fingerprints: null,
      fingerprintProfiles: {},
      isLoadingFingerprints: false,
      isLoadingFingerprintProfiles: false,
      isSearching: false,
      profile: null,
      sort: "last_seen",
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedFingerprint(fingerprint) {
      if (!fingerprint) {
        return;
      }
      this.$router.push({
        path: '/sms-profile/fingerprint/' + encodeURIComponent(fingerprint)
      });
    },
    //--------------------------------------------------------------------------
    clickedSender(sender) {
      if (!sender) {
        return;
      } else if (sender == this.sender) {
        return;
      }
      this.$router.push({
        path: '/sms-profile/sender/' + encodeURIComponent(sender)
      });
    },
    //--------------------------------------------------------------------------
    clickedSort(sort) {
      if (this.isLoadingFingerprints) {
        return;
      }
      this.sort = sort;
      this.loadDomainFingerprints();
    },
    //--------------------------------------------------------------------------
    loadDomainFingerprints() {
      if (this.isLoadingFingerprints) {
        return;
      } else {
        this.errorFingerprints = null;
        this.isLoadingFingerprints = true;
        this.fingerprints = null;
      }

      var that = this;
      var method = "GET";
      var url = "https://sms-profile.platform.robocalls.ai/domain/fingerprints?domain=" + encodeURIComponent(this.domain) + "&sort=" + encodeURIComponent(this.sort);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isLoadingFingerprints = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorFingerprints = message;
            } else {
              that.errorFingerprints = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseFingerprints(result);
            } catch (err) {
              that.errorFingerprints = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isLoadingFingerprints = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isLoadingFingerprints = false;
          that.errorFingerprints = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    loadFingerprintProfiles() {
      if (this.isLoadingFingerprintProfiles) {
        return;
      } else {
        this.errorFingerprintProfiles = null;
        this.fingerprintProfiles = {};
        this.isLoadingFingerprintProfiles = true;
      }

      var body = {
        fingerprints: []
      };
      for (var x = 0; x < this.fingerprints.length; x++) {
        body.fingerprints.push(this.fingerprints[x].fingerprint);
      }
      body = JSON.stringify(body);

      var that = this;
      var method = "POST";
      var url = "https://sms-profile.platform.robocalls.ai/fingerprint/profiles";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isLoadingFingerprintProfiles = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorFingerprintProfiles = message;
            } else {
              that.errorFingerprintProfiles = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseFingerprintProfiles(result);
            } catch (err) {
              that.errorFingerprintProfiles = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isLoadingFingerprintProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isLoadingFingerprintProfiles = false;
          that.errorFingerprintProfiles = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.profile) {
        this.errorMessage = "Server did not return profile data";
        return;
      }
      this.profile = response.profile;
      this.loadDomainFingerprints();
    },
    //--------------------------------------------------------------------------
    processResponseFingerprints(response) {
      if (!response || !response.fingerprints) {
        this.errorFingerprints = "Server did not return fingerprints";
        return;
      } else if (response.fingerprints.length == 0) {
        this.errorFingerprints = "No fingerprints were found that contain " + this.domain;
        return;
      }
      this.fingerprints = response.fingerprints;
      this.loadFingerprintProfiles();
    },
    //--------------------------------------------------------------------------
    processResponseFingerprintProfiles(response) {
      if (!response || !response.profiles) {
        this.errorFingerprintProfiles = "Server did not return profiles";
        return;
      }
      this.fingerprintProfiles = {};
      for (var x = 0; x < response.profiles.length; x++) {
        this.fingerprintProfiles[response.profiles[x].fingerprint] = response.profiles[x];
      }

      // Delete any fingerprints we don't have a profile for
      var self = this;
      this.fingerprints = this.fingerprints.filter(function(fingerprint) {
        return self.fingerprintProfiles[fingerprint.fingerprint] != undefined;
      });
      if (this.fingerprints.length == 0) {
          this.errorFingerprintProfiles = "No recent messages were found";
      }
    },
    //--------------------------------------------------------------------------
    search() {

      // Make sure we have a domain
      if (!this.domain) {
        return;
      }

      // Make sure we're not currently searching
      if (this.isSearching) {
        return;
      } else {
        this.isSearching = true;
        this.errorMessage = null;
        this.profile = null;
      }

      var that = this;
      var method = "GET";
      var url = "https://sms-profile.platform.robocalls.ai/domain/profile?domain=" + encodeURIComponent(this.domain);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isSearching = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isSearching = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isSearching = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    reasonToString(reason) {
      switch (reason.toLowerCase()) {
        case "domain_reputation":
          return "Domain";
        case "fingerprint_override":
          return "Fingerprint";
        case "sender_override":
          return "Sender";
        case "ml_model":
          return "ML Model";
        default:
          return reason;
      }
    },
    //--------------------------------------------------------------------------
    truncateMessage(message) {
      if (!message) {
        return ""
      }
      if (message.length > 95) {
        message = message.substring(0, 95) + "...";
      }
      return message;
    }
  },
  mounted() {
    if (this.$route.params.domain) {
      this.domain = this.$route.params.domain;
      this.search();
    }
  }
}
</script>
